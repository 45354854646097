import { RichText } from "@graphcms/rich-text-react-renderer";
import { RichTextContent } from "@graphcms/rich-text-types";
import Link from "next/link";

type Props = {
  content: RichTextContent;
};

const RichTextRenderer = (props: Props) => {
  return (
    <RichText
      content={props.content}
      renderers={{
        a: ({ children, openInNewTab, href, rel, className, ...rest }) => {
          if (className?.includes("rt-button")) {
            return (
              <Link href={href || "#"} passHref>
                <a target={openInNewTab ? "_blank" : "_self"}>
                  <button
                    className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md drop-shadow-md bg-secondary hover:bg-lime-500"
                    {...rest}
                  >
                    {children}
                  </button>
                </a>
              </Link>
            );
          }

          if (className?.includes("rt-white-button")) {
            return (
              <Link href={href || "#"} passHref>
                <a target={openInNewTab ? "_blank" : "_self"}>
                  <button
                    className="inline-flex items-center justify-center px-5 py-3 text-base font-medium border border-transparent rounded-md bg-slate-50 drop-shadow-md text-primary hover:bg-indigo-50"
                    {...rest}
                  >
                    {children}
                  </button>
                </a>
              </Link>
            );
          }

          if (href?.match(/^https?:\/\/|^\/\//i)) {
            return (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={href}
                target={openInNewTab ? "_blank" : "_self"}
                rel={rel || "noopener noreferrer"}
                {...rest}
              >
                {children}
              </a>
            );
          }

          return (
            <Link href={href || "#"} passHref>
              <a {...rest}>{children}</a>
            </Link>
          );
        },
        p: ({ children, ...rest }) => {
          return (
            <p className="mt-4 text-gray-500" {...rest}>
              {children}
            </p>
          );
        },
        h2: ({ children, ...rest }) => {
          return (
            <h2
              className="text-3xl font-extrabold tracking-tight text-gray-900"
              {...rest}
            >
              {children}
            </h2>
          );
        },
        h3: ({ children, ...rest }) => {
          return (
            <h3 className="mt-4 text-xl font-bold" {...rest}>
              {children}
            </h3>
          );
        },
      }}
    />
  );
};

export default RichTextRenderer;
