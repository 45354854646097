// import Botpoison from "@botpoison/browser";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { Widget } from "@uploadcare/react-widget";
import axios from "axios";
import Link from "next/link";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";

import { SplitBrandContactFormComponentFragment } from "../../interfaces";
import classNames from "../../lib/classNames";
import SocialMediaIcons from "./../SocialMediaIcons";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  subject: string;
  message: string;
};

type Props = Partial<SplitBrandContactFormComponentFragment>;

const SplitBrandContactForm = (props: Props) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormData>();
  const [files, setFiles] = useState<string | null>();
  const [captcha, setCaptcha] = useState<string | null>();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onSubmit = handleSubmit((data) => {
    // const botpoison = new Botpoison({
    //   publicKey: process.env.NEXT_PUBLIC_BOTPOISON_PUBLIC_KEY!,
    // });

    // botpoison
    //   .challenge()
    //   .then(({ solution }) => {
    axios
      .post("https://submit-form.com/6ABk13kx", {
        // _botpoison: solution,
        files: files,
        "g-recaptcha-response": captcha,
        ...data,
      })
      .catch((err) => {
        console.error(err);
      });
    // })
    // .catch((err) => {
    //   console.error(err);
    // });
  });

  return (
    <div className="bg-gray-100" id="contact-us">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Contact us</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative px-6 py-10 overflow-hidden bg-primary sm:px-10 xl:p-12">
              <div
                className="absolute inset-0 pointer-events-none sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">{props.title}</h3>
              <p className="max-w-3xl mt-6 text-base text-indigo-50">
                {props.description}
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Phone number</span>
                </dt>
                <Link href="tel:+447503753001">
                  <dd className="flex text-base text-indigo-50">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-indigo-200 cursor-pointer"
                      aria-hidden="true"
                    />
                    <span className="ml-3 cursor-pointer">
                      +44 750 375 3001
                    </span>
                  </dd>
                </Link>
                <dt>
                  <span className="sr-only">Phone number</span>
                </dt>
                <Link href="tel:+905413625030">
                  <dd className="flex text-base text-indigo-50">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-indigo-200 cursor-pointer"
                      aria-hidden="true"
                    />
                    <span className="ml-3 cursor-pointer">
                      +90 541 362 5030
                    </span>
                  </dd>
                </Link>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <Link href="mailto:info@welfareabroad.com">
                  <dd className="flex text-base text-indigo-50">
                    <MailIcon
                      className="flex-shrink-0 w-6 h-6 text-indigo-200 cursor-pointer"
                      aria-hidden="true"
                    />
                    <span className="ml-3 cursor-pointer">
                      info@welfareabroad.com
                    </span>
                  </dd>
                </Link>
              </dl>
              <SocialMediaIcons />
            </div>

            {/* Contact form */}
            <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">
                Send us a message
              </h3>
              <form
                className="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                // method="POST"
                // action="https://submit-form.com/6ABk13kx"
                onSubmit={onSubmit}
                // data-botpoison-public-key={
                //   process.env.NEXT_PUBLIC_BOTPOISON_PUBLIC_KEY
                // }
              >
                <input
                  type="text"
                  name="_email.replyto"
                  defaultValue={watch("email")}
                  className="hidden"
                />
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-900"
                    >
                      First name{" "}
                    </label>
                    {errors.firstName && (
                      <span className="text-sm text-red-500">* Required</span>
                    )}
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      autoComplete="given-name"
                      className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                      {...register("firstName", { required: true })}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Last name{" "}
                    </label>
                    {errors.lastName && (
                      <span className="text-sm text-red-500">* Required</span>
                    )}
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      autoComplete="family-name"
                      className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                      {...register("lastName", { required: true })}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    {errors.email && (
                      <span className="text-sm text-red-500">* Required</span>
                    )}
                  </div>
                  <div className="mt-1">
                    <input
                      type="email"
                      autoComplete="email"
                      className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                      {...register("email", { required: true })}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Phone
                    </label>
                    <span id="phone-optional" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      autoComplete="tel"
                      className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                      aria-describedby="phone-optional"
                      {...register("phone")}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="subject"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Subject
                    </label>
                  </div>
                  <div className="mt-1">
                    <select
                      className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                      {...register("subject")}
                      defaultValue="Hair Transplant"
                    >
                      {/* deepcode ignore ReactDeprecatedElementProp: no need for complex syntax */}
                      <option value="Hair Transplant">Hair Transplant</option>
                      <option value="Cosmetic Surgeries">
                        Cosmetic Surgeries
                      </option>
                      <option value="Affiliate">Affiliate</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Message
                    </label>
                    <div className="flex justify-between space-x-3">
                      {errors.message && (
                        <span className="text-sm text-red-500">* Required</span>
                      )}
                      <span id="message-max" className="text-sm text-gray-500">
                        Max. 500 characters
                      </span>
                    </div>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      rows={4}
                      className="block w-full px-4 py-3 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                      aria-describedby="message-max"
                      defaultValue={""}
                      {...register("message", { required: true })}
                    />
                  </div>
                </div>
                <div className="justify-between md:flex sm:col-span-2">
                  <div className="pb-10 md:pb-0">
                    <div className="flex justify-between">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Your Photos
                      </label>
                      <div className="flex justify-between space-x-3">
                        <span
                          id="message-max"
                          className="text-sm text-gray-500"
                        >
                          Optional
                        </span>
                      </div>
                    </div>
                    <div className="mt-1 text-black">
                      <input type="hidden" name="photos" value={files || ""} />
                      <Widget
                        publicKey={
                          process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY!
                        }
                        tabs="file camera"
                        localeTranslations={{
                          buttons: {
                            choose: {
                              files: {
                                other: "Choose files to upload",
                              },
                            },
                          },
                        }}
                        multiple
                        onChange={(info) => {
                          setFiles(info.cdnUrl);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:items-center sm:col-span-2 sm:flex sm:justify-between">
                  <ReCAPTCHA
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
                    onChange={(response) => {
                      setCaptcha(response);
                      if (response) {
                        setButtonDisabled(false);
                      }
                    }}
                    onExpired={() => {
                      setCaptcha("");
                      setButtonDisabled(true);
                    }}
                  />
                  {!isSubmitSuccessful ? (
                    <button
                      type="submit"
                      className={classNames(
                        buttonDisabled
                          ? "bg-gray-500"
                          : "bg-secondary hover:bg-lime-500 focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:outline-none",
                        "inline-flex items-center justify-center w-full px-6 py-3 mt-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm sm:w-auto"
                      )}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  ) : (
                    <div className="mt-10">
                      <p className="text-xl text-right">
                        Thank you for your submission!
                      </p>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitBrandContactForm;
