import Link from "next/link";

import { PostCardComponentFragment } from "../interfaces";
import LogoWithBackground from "../public/logo_with_background.png";
import BlurImage from "./BlurImage";

type Props = {
  post: PostCardComponentFragment;
  treatmentCard?: boolean;
};

const PostCard = ({ post, treatmentCard = false }: Props) => {
  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="flex-shrink-0">
        <BlurImage
          className="object-cover w-full h-48 rounded-xl"
          width={413}
          height={192}
          src={post.image?.url || LogoWithBackground}
          alt={post.imageAlt || post.title}
          sizes="(max-width: 768px) 95vw,
                  (max-width: 1200px) 33vw,
                  33vw"
        />
      </div>
      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
        <div className="flex-1">
          {treatmentCard && (
            <p className="text-sm font-medium text-indigo-600">
              <Link
                href={"/" + post.categories.map((c) => c.seo.slug).join("/")}
                passHref
              >
                <a className="hover:underline">
                  {post.categories.slice(-1)[0].name}
                </a>
              </Link>
            </p>
          )}
          <Link
            href={
              treatmentCard
                ? "/" +
                  [
                    ...post.categories.map((c) => c.seo.slug),
                    post.seo.slug,
                  ].join("/")
                : "/blog/" + post.seo.slug
            }
            passHref
          >
            <a className="block mt-2">
              <p className="text-xl font-semibold text-gray-900">
                {post.title}
              </p>
              <p className="mt-3 text-base text-gray-500">{post.description}</p>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
